.Arrow {
  position: absolute;
  z-index: 3;
  top: 43%;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.Reverse {
  transform: rotate(180deg);
  margin-left: 1em;
  left: 1rem;
}

.Right {
  right: 1rem;
  top: 43.8%;
  margin-right: 1em;
}
